<!-- 持续发展 -->
<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/cooperation/develop/ja/绿色制造.png" class="images"
          v-show="bottomBorderIndex == 1">
        <img src="@/assets/cooperation/develop/ja/永续经营.png" class="images"
          v-show="bottomBorderIndex == 2">
        <img src="@/assets/cooperation/develop/ja/员工关怀.png" class="images"
          v-show="bottomBorderIndex == 3">
        <img src="@/assets/cooperation/develop/ja/新技术研发.png" class="images"
          v-show="bottomBorderIndex == 4">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容区域 -->
    <div class="content">
      <!-- 头部标题 -->
      <div class="title">
        <ul>
          <li v-for="item in titleList" :key="item.id" @click="titleBtn(item.id)"
            :class="{ bottomBorder: bottomBorderIndex == item.id }">{{ item.name }}</li>
        </ul>
      </div>
      <!-- 文字图片 -->
      <div v-for="item in titleUrl" :key="item.id">
        <img :src="item.url" v-if="item.id == bottomBorderIndex" class="titleUrl">
      </div>
      <!-- 中间内容 -->
      <p v-for="item in tableList" :key="item.id">{{ item.title }}</p>
      <!-- 下载 -->
      <img :src="reportUrl" class="report" @click="downloadPdf">
    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/cooperation/develop/zh/绿色制造.png" class="images"
          v-show="bottomBorderIndex == 1">
        <img src="@/assets/cooperation/develop/zh/永续经营.png" class="images"
          v-show="bottomBorderIndex == 2">
        <img src="@/assets/cooperation/develop/zh/员工关怀.png" class="images"
          v-show="bottomBorderIndex == 3">
        <img src="@/assets/cooperation/develop/zh/新技术研发.png" class="images"
          v-show="bottomBorderIndex == 4">
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <div class="content">
          <!-- 中间内容头部区域 -->
          <div class="title">
            <ul>
              <li v-for="item in titleListMove" :key="item.id" @click="titleBtn(item.id)"
                :class="{ bottomBorder: bottomBorderIndex == item.id }">{{ item.name }}
              </li>
            </ul>
            <!-- 中间内容文字图片区域 -->
            <div v-for="item in titleUrl" :key="item.id">
             <img :src="item.url" v-if="item.id == bottomBorderIndex" class="titleUrl">
          </div>
          <!-- 文字内容 -->
          <p v-for="item in tableList" :key="item.id">{{ item.title }}</p>
          <!-- 底部图片区域 -->
          <img :src="reportUrl" class="report titleUrl" @click="downloadPdf">
      </div>
    </div>
    <!-- 底部 -->
    <move_foot style="margin-top: -2.3rem;"></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import {downloadPdf} from '@/utils/downloadExportFile.js'
export default {
  components: { foot, headers, move_header, move_foot },

  setup() {
    const route = useRoute();
    const state = reactive({
      bottomBorderIndex: 1,
      // 文字内容展示的数据
      tableList: [],
      titleList: [
        { id: 4, name: "共同開発" },
        { id: 3, name: "社員ケア" },
        { id: 2, name: "持続的経営" },
        { id: 1, name: "グリーン製造" },
      ],
      titleListMove:[
        { id: 1, name: "グリーン製造" },
        { id: 2, name: "持続的経営" },
        { id: 3, name: "社員ケア" },
        { id: 4, name: "共同開発" },
      ],
      reportUrl: require('@/assets/cooperation/develop/ja/报告下载端.png'),
      titleUrl: [
        { id: 1, url: require('@/assets/cooperation/develop/ja/绿色制造-text.png') },
        { id: 2, url: require('@/assets/cooperation/develop/ja/永续经营-text.png') },
        { id: 3, url: require('@/assets/cooperation/develop/ja/员工关怀-text.png') },
        { id: 4, url: require('@/assets/cooperation/develop/ja/新技术发展-text.png') },
      ],
      // 绿色制造文字内容
      greenWord: [
        { id: 1, title: "私たちは、「強をして、遠くへ行って、100年の光電企業をする。」というビジョンを企業の最初の核心として堅持し、ステークホルダーと結合して、社会、環境と管理の3つの面から、会社の持続可能な発展戦略システムに科学的かつ合理的な決定管理構造を構築・確立し、持続可能な発展目標を設定し、国家の「3060」目標に積極的に応え、エネルギー消費の低炭素化、資源利用のリサイクル化、生産プロセス清浄化、製品供給のグリーン化、供給物流のグリーン化、生産方式のデジタル化などの方向を通じて、企業の持続可能な発展戦略を実行する。" },
        { id: 2, title: "管理システムの建設を強化し、管理能力の向上を促進する。合法的な管理システムを遵守することは、会社の持続可能な発展に対して極めて重要な役目を果たし、またグリーン製造システムの構築、安全な生産、環境の保護、グリーン智造を推進して、企業が未来にグリーンと低炭素の発展を実現するために必ず通らなければならない道である。" },
        { id: 3, title: "そのため、会社は文書やシステムを常に改訂、調整、追加し、法令に準拠させることで、企業の持続可能な発展に助力する。" },
        { id: 4, title: "2022年、私たちは関連制度に合格し、関連認証を得た。同時に、ISO27001情報安全管理体系の認証を獲得するために、情報安全企業を構築し、顧客と市場の信頼をさらに獲得し、企業の評判を高める。" },
        { id: 5, title: "現在、気候変動の緩和は世界的な共通認識になっている。国家の「3060」計画に応えるために、私たちは積極的に各種の措置を通じて、設備の研究開発、新材料の開発と使用、プロセスの改善、エネルギー消費の目標設定、プロセスの電子環境化などを通じて、炭素排出量を削減し、生態環境を改善するための様々な措置を積極的に採用し、積極的で友好的な発展、企業と環境の積極的友好発展とウィンウィンを実現し、企業のグリーン高品質の持続可能な発展を実現する。 2022年、当社の電力消費量は15,201,322(kWh)に達する。" },
        { id: 6, title: "2023年、私たちは省エネと炭素削減の目標を設定また推進し、再生可能エネルギーの使用率を5％にする。同時に私たちは厳格な水資源管理体系と水資源グループを設立した。2022合計節水量は18000トンで、2021年の同レベルに対して12.5%低減し、表流水と雨水及び水資源の再利用率を高め、同レベルの水使用量をさらに10％低減することを目標とする。" },
        { id: 7, title: "廃棄物に対して、国や地方の関連法規の原則を厳守して処理し、「三化原則」と「統一回収、分類管理、標準処理」の処理原則に従って、生産・操業過程で発生する無害廃棄物と有害廃棄物を区別する廃棄物管理システムを構築している。無害な廃棄物は繰り返しリサイクルされる。" },
        {id:8,title:"排気ガスの発生に対して、集中管理、集中処理、集中排出かつ監視測定を行い、基準を達成したあと排出する。政府発行の資格のある検定機関を通じて、検定と監視のデータがすべての基準を達成したことを示している。"}
      ],
      // 经营文字内容
      manageWord: [
        { id: 1, title: "盛泰光電科技股份有限公司は、中国重慶双橋経開区に本社を置き、精密カメラモジュールの設計、研究開発、製造と販売を専門とするハイテク企業である。会社の未来発展戦略は持続化に従って発展し、グローバル化レイアウトの計画は着実に推進している。同社は現在、重慶、江西・分宜、インド・デリーの3カ所に知能型製造基地があり、また重慶ソフトウェアアルゴリズム会社、深セン新技術研究開発センター、シンガポール会社、海外研究開発センターなどの多数の生産基地とサービス機構がある。" },
        { id: 2, title: "盛泰光電の製品とサービスは主に携帯電話、タブレットPC、ノートパソコン、デジタルカメラ、自動車映像、自動運転、スマートウェア、医療、スマートホーム、スマートシティ、セキュリティ、ドローン、航空宇宙どの領域におけるハイエンドカメラアプリケーションに重点を置いており、強力な技術サポートを通じて、顧客とユーザーのニーズに応えるサービスを提供している。" },
        { id: 3, title: "盛泰光電の未来の発展策略は映像技術のハードウェア製造とソフトウェアサービス領域に持続的に延伸し、科学技術の革新を成長動力とする企業に発展することである。" },
        { id: 4, title: "万物が相互接続され、人と機械が相互に作用するインテリジェント接続の時代において、私たちは研究開発と革新に力を入れ、全面的な可視的認知技術を用いて、企業、個人、設備がインテリジェント世界を構築する可能性を実現する。また、多様なお客様のニーズに対応する豊富なインテリジェント製品を提供し、インテリジェントな接続時代の利便性を共有する。今後、既存のビジネス分野に加え、インテリジェントなIoTアズアサービス（AIoTaas）モデルに移行し、インテリジェントカメラの新しいソリューションを通じて、さまざまな業界のユーザーの幅広いニーズに対応する。" },
      ],
      // 员工关怀
      empWord: [
        { id: 1, title: "企業の発展は従業員の努力を離れられず、従業員の権益保障も企業発展の「人中心」理念を離れられない。" },
        { id: 2, title: "法律に準拠して経営する企業として、企業の発展は従業員の努力を離れられず、従業員の権益保障も企業の「人中心」理念を離れられないことを知って、私たちは法律法規、業界標準及び企業自身の状況に基づき、一連の制度と書類を創立し、従業員の合法的権益を保障する。" },
        { id: 3, title: "私たちは常に「安全第一、予防優先、総合管理、人間本位」の生産安全方針を堅持して、従業員に安全で健康的な職場環境を提供することに力を入れている。そして、「安全な生産、健康な帰宅」の理念を提唱し、健全な安全生産と労働健康管理システムを確立した。例えば、『職業健康管理方法』に対して長期的な公示を行い、年間の安全生産知識に対してトレーニングを行い、生産過程において各自がお互いに守る三つの安全防御意識を確立するようにする。" },
        { id: 4, title: "同時に、私たちは人材が科学技術革新の核心原動力であることを理解して、人材は企業の第一戦略資源として、外部募集及び内部研修、昇進、訓練などの多種の形式を通じて人材を育成する。また、完備した職業発展ルートと人材発展システムがあり、従業員に双方向の発展ルートを提供し、自身の価値と会社の共同成長を実現する。" },
        { id: 5, title: "2022年に、社内講師の育成と認証をスタートし、全面的に社内講師陣を創立し、企業のために企業文化を伝播し、仕事経験と知識を伝承し、有力な通路を提供した。従業員の職業発展のリーダとして、充分に「伝え・助け・連れ」の仕事をしっかり行い、企業のために企業発展に適する人材を育成して提供する。" },
        { id: 6, title: "「健康的仕事する、楽しく生活する」というのは私たちが常に提唱していることである。私たちは各従業員を盛泰大家族の一員として、常に従業員の心身健康に関心を持って、「会社オープンデー」、「園遊会親子イベント」、「社員誕生日会」などの活動を行って、従業員の日常生活を豊かにして、みんなの生活と仕事のバランスを達成する。" },
      ],
      // 研发与发展
      RDWord: [
        { id: 1, title: "グリーン技術の研究開発と利用は企業の将来の低炭素循環発展の重要な方向であることを認識している。私たちは資金を投入して戸外エネルギー貯蔵と太陽光発電などのグリーン技術製品の使用を行い、グリーン技術への投資を増やし続ける。同時に、プロセス改善を通じて一部のエネルギーの再利用率を向上させる。" },
        { id: 2, title: "科学技術革新の面では、絶えず努力することによって、私たちは授権特許452項、コンピュータソフトウェア著作権86項、発明特許109項、実用新案特許250項を有して、江西省文化企業トップ20、江西省級情報化と工業化融合模範企業、江西省級専精特新企業、江西省優強企業トップ50、江西省級企業技術センターなどを獲得して、そして市級栄誉「科学技術型企業」 「技術革新センター」 「中小企業技術研究開発センター」、「双百企業」 「製造業トップ100」 「ディジタルプラント」 「重慶市企業技術センター」 「両化融合管理システム評定証書」 「大足区工業トップ20」 「新興産業」 「知的財産権優位企業」 「インテリジェントターミナル企業」などの特別栄誉を獲得した。" },
        { id: 3, title: "サプライチェーン管理は企業経営の重要な一環として、グリーンサプライチェーンとは、自社の省エネルギー、排出削減、環境保護に取り組むと同時に、上下流企業を牽引し、資源・エネルギーの利用効率を高め、環境パフォーマンスを改善し、サプライチェーン全体の持続可能な発展を実現することである。" },
        { id: 4, title: "私たちはレスポンシブル・ビジネス・アライアンス(RBA)を厳格に遵守して、サプライヤーのESG審査プロセスを積極的に導入して、責任購買制度の建設を推進する。また、サプライヤーを選択する時、労働基準、健康と安全、環境影響、商業の道徳の4つの次元から、サプライヤーの社会責任の表現を総合的に審査し、強化している。" },
        { id: 5, title: "盛泰光科は強い社会的責任感を持つ企業として、企業の発展は社会の支持を離れられないことをよく知っている。私たちは、ボランティア活動や環境保全などの公共福祉事業に取り組み、企業の社会的責任を果たし、社会に還元している。" },
      ]
    });
    let methods = {
      // 点击标题list
      titleBtn(id) {
        state.bottomBorderIndex = id
        switch (id) {
          case 1:
            state.tableList = state.greenWord
            break;
          case 2:
            state.tableList = state.manageWord
            break;
          case 3:
            state.tableList = state.empWord
            break;
          case 4:
            state.tableList = state.RDWord
            break;

          default:
            break;
        }
      },
                  // 点击下载中文报告
       downloadPdf() {
        downloadPdf('https://www.shine-optics.com/video/ows/sustainable_development.pdf','sustainable_development')
   }
    }
    onMounted(() => {
      window.scrollTo(0, 0)
      state.tableList = state.greenWord
    });
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

<style scoped lang="less">
.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {

  width: 100%;

  .bottomBorder {
    border-bottom: 3px solid red;
  }

  .content {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    width: 70%;
    height: auto;

    p {
      padding: 0 30px;
      text-indent: 2em;
      Letter-spacing: 1px;
      font-size: 1.25rem;
    }

    .title {
      width: 100%;
      height: 50px;
      margin-top: 20px;

      li {
        float: right;
        cursor: pointer;
        display: inline-block;
        margin-right: 10px;
        font-size: 25px;
      }
    }

    .report {
      width: 100%;
      cursor: pointer;
      margin-top: 30px;
    }

    .titleUrl {
      width: 100%;

    }
  }
}


.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;
    p {
      padding: 0 1em;
      text-indent: 3.5em;
      Letter-spacing: 1px;
      font-size: 3.75rem;
      text-align: left;
    }
    .bottomBorder{
      border-bottom: 10px solid red;
    }
    .content {
      position: relative;
      padding: 30px;
      .titleUrl{
        width: 100%;
        margin-top: 30px;
      }
      .report{
        cursor: pointer;
      }
      .title {
      width: 100%;
      margin: 0 0 35px 0;
        text-align: center;
      li {
        cursor: pointer;
        display: inline-block;
        margin-right: 20px;
        font-size: 3.75rem;
        
      }
    }

    }
  }
}</style>